/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { hex2rgba as t, isNamedColor as e, getNamedColorCopy as r, hsla2rgba as n } from "../../colorUtils.js";
import u from "../../core/Error.js";
import { ColorMatrixEffect as a, OpacityEffect as o, HueRotateEffect as c, BlurEffect as i, DropShadowEffect as s, BloomEffect as l } from "./effects.js";
import { c as f } from "../../chunks/_commonjsHelpers.js";
import { canInterpolateEffects as p, normalizeEffects as h } from "./utils.js";
var m,
  g,
  v = {
    exports: {}
  };
g = function () {
  function t(t, e) {
    function r() {
      this.constructor = t;
    }
    r.prototype = e.prototype, t.prototype = new r();
  }
  function e(t, r, n, u) {
    var a = Error.call(this, t);
    return Object.setPrototypeOf && Object.setPrototypeOf(a, e.prototype), a.expected = r, a.found = n, a.location = u, a.name = "SyntaxError", a;
  }
  function r(t, e, r) {
    return r = r || " ", t.length > e ? t : (e -= t.length, t + (r += r.repeat(e)).slice(0, e));
  }
  function n(t, r) {
    var n,
      u = {},
      a = (r = void 0 !== r ? r : {}).grammarSource,
      o = {
        start: Lt
      },
      c = Lt,
      i = "none",
      s = ")",
      l = ",",
      f = "(",
      p = "%",
      h = "px",
      m = "cm",
      g = "mm",
      v = "in",
      d = "pt",
      y = "pc",
      A = "deg",
      w = "rad",
      x = "grad",
      b = "turn",
      C = "#",
      F = ".",
      E = "e",
      j = /^[ \t\n\r]/,
      $ = /^[a-z\-]/,
      O = /^[0-9a-fA-F]/,
      k = /^[+\-]/,
      q = /^[0-9]/,
      S = Bt("none"),
      z = Ut("none", !1),
      R = Ut(")", !1),
      I = Ut(",", !1),
      M = Bt("whitespace"),
      P = Ht([" ", "\t", "\n", "\r"], !1, !1),
      N = Bt("function"),
      T = Ut("(", !1),
      U = Bt("identifier"),
      H = Ht([["a", "z"], "-"], !1, !1),
      _ = Bt("percentage"),
      B = Ut("%", !1),
      D = Bt("length"),
      G = Ut("px", !1),
      J = Ut("cm", !1),
      K = Ut("mm", !1),
      L = Ut("in", !1),
      Q = Ut("pt", !1),
      V = Ut("pc", !1),
      W = Bt("angle"),
      X = Ut("deg", !1),
      Y = Ut("rad", !1),
      Z = Ut("grad", !1),
      tt = Ut("turn", !1),
      et = Bt("number"),
      rt = Bt("color"),
      nt = Ut("#", !1),
      ut = Ht([["0", "9"], ["a", "f"], ["A", "F"]], !1, !1),
      at = Ht(["+", "-"], !1, !1),
      ot = Ht([["0", "9"]], !1, !1),
      ct = Ut(".", !1),
      it = Ut("e", !1),
      st = function () {
        return [];
      },
      lt = function (t, e) {
        return {
          type: "function",
          name: t,
          parameters: e || []
        };
      },
      ft = function (t, e) {
        return e.length > 0 ? se(t, e, 3) : [t];
      },
      pt = function (t) {
        return {
          type: "quantity",
          value: t.value,
          unit: t.unit
        };
      },
      ht = function (t) {
        return {
          type: "color",
          colorType: t.type,
          value: t.value
        };
      },
      mt = function (t) {
        return t;
      },
      gt = function () {
        return Tt();
      },
      vt = function (t) {
        return {
          value: t,
          unit: "%"
        };
      },
      dt = function (t) {
        return {
          value: t,
          unit: "px"
        };
      },
      yt = function (t) {
        return {
          value: t,
          unit: "cm"
        };
      },
      At = function (t) {
        return {
          value: t,
          unit: "mm"
        };
      },
      wt = function (t) {
        return {
          value: t,
          unit: "in"
        };
      },
      xt = function (t) {
        return {
          value: t,
          unit: "pt"
        };
      },
      bt = function (t) {
        return {
          value: t,
          unit: "pc"
        };
      },
      Ct = function (t) {
        return {
          value: t,
          unit: "deg"
        };
      },
      Ft = function (t) {
        return {
          value: t,
          unit: "rad"
        };
      },
      Et = function (t) {
        return {
          value: t,
          unit: "grad"
        };
      },
      jt = function (t) {
        return {
          value: t,
          unit: "turn"
        };
      },
      $t = function (t) {
        return {
          value: t,
          unit: null
        };
      },
      Ot = function () {
        return {
          type: "hex",
          value: Tt()
        };
      },
      kt = function (t) {
        return {
          type: "function",
          value: t
        };
      },
      qt = function () {
        return {
          type: "named",
          value: Tt()
        };
      },
      St = function () {
        return parseFloat(Tt());
      },
      zt = 0,
      Rt = 0,
      It = [{
        line: 1,
        column: 1
      }],
      Mt = 0,
      Pt = [],
      Nt = 0;
    if ("startRule" in r) {
      if (!(r.startRule in o)) throw new Error("Can't start parsing from rule \"" + r.startRule + '".');
      c = o[r.startRule];
    }
    function Tt() {
      return t.substring(Rt, zt);
    }
    function Ut(t, e) {
      return {
        type: "literal",
        text: t,
        ignoreCase: e
      };
    }
    function Ht(t, e, r) {
      return {
        type: "class",
        parts: t,
        inverted: e,
        ignoreCase: r
      };
    }
    function _t() {
      return {
        type: "end"
      };
    }
    function Bt(t) {
      return {
        type: "other",
        description: t
      };
    }
    function Dt(e) {
      var r,
        n = It[e];
      if (n) return n;
      for (r = e - 1; !It[r];) r--;
      for (n = {
        line: (n = It[r]).line,
        column: n.column
      }; r < e;) 10 === t.charCodeAt(r) ? (n.line++, n.column = 1) : n.column++, r++;
      return It[e] = n, n;
    }
    function Gt(t, e, r) {
      var n = Dt(t),
        u = Dt(e);
      return {
        source: a,
        start: {
          offset: t,
          line: n.line,
          column: n.column
        },
        end: {
          offset: e,
          line: u.line,
          column: u.column
        }
      };
    }
    function Jt(t) {
      zt < Mt || (zt > Mt && (Mt = zt, Pt = []), Pt.push(t));
    }
    function Kt(t, r, n) {
      return new e(e.buildMessage(t, r), t, r, n);
    }
    function Lt() {
      var t;
      return (t = Qt()) === u && (t = Vt()), t;
    }
    function Qt() {
      var e, r;
      return Nt++, e = zt, Zt(), t.substr(zt, 4) === i ? (r = i, zt += 4) : (r = u, 0 === Nt && Jt(z)), r !== u ? (Zt(), Rt = e, e = st()) : (zt = e, e = u), Nt--, e === u && 0 === Nt && Jt(S), e;
    }
    function Vt() {
      var t, e;
      if (t = [], (e = Wt()) !== u) for (; e !== u;) t.push(e), e = Wt();else t = u;
      return t;
    }
    function Wt() {
      var e, r, n, a;
      return e = zt, Zt(), (r = te()) !== u ? (Zt(), (n = Xt()) === u && (n = null), Zt(), 41 === t.charCodeAt(zt) ? (a = s, zt++) : (a = u, 0 === Nt && Jt(R)), a !== u ? (Zt(), Rt = e, e = lt(r, n)) : (zt = e, e = u)) : (zt = e, e = u), e;
    }
    function Xt() {
      var e, r, n, a, o, c, i, s;
      if (e = zt, (r = Yt()) !== u) {
        for (n = [], a = zt, o = Zt(), 44 === t.charCodeAt(zt) ? (c = l, zt++) : (c = u, 0 === Nt && Jt(I)), c === u && (c = null), i = Zt(), (s = Yt()) !== u ? a = o = [o, c, i, s] : (zt = a, a = u); a !== u;) n.push(a), a = zt, o = Zt(), 44 === t.charCodeAt(zt) ? (c = l, zt++) : (c = u, 0 === Nt && Jt(I)), c === u && (c = null), i = Zt(), (s = Yt()) !== u ? a = o = [o, c, i, s] : (zt = a, a = u);
        Rt = e, e = ft(r, n);
      } else zt = e, e = u;
      return e;
    }
    function Yt() {
      var t, e;
      return t = zt, (e = re()) === u && (e = ne()) === u && (e = ue()) === u && (e = ae()), e !== u && (Rt = t, e = pt(e)), (t = e) === u && (t = zt, (e = oe()) !== u && (Rt = t, e = ht(e)), t = e), t;
    }
    function Zt() {
      var e, r;
      for (Nt++, e = [], j.test(t.charAt(zt)) ? (r = t.charAt(zt), zt++) : (r = u, 0 === Nt && Jt(P)); r !== u;) e.push(r), j.test(t.charAt(zt)) ? (r = t.charAt(zt), zt++) : (r = u, 0 === Nt && Jt(P));
      return Nt--, r = u, 0 === Nt && Jt(M), e;
    }
    function te() {
      var e, r, n;
      return Nt++, e = zt, (r = ee()) !== u ? (40 === t.charCodeAt(zt) ? (n = f, zt++) : (n = u, 0 === Nt && Jt(T)), n !== u ? (Rt = e, e = mt(r)) : (zt = e, e = u)) : (zt = e, e = u), Nt--, e === u && (r = u, 0 === Nt && Jt(N)), e;
    }
    function ee() {
      var e, r, n;
      if (Nt++, e = zt, r = [], $.test(t.charAt(zt)) ? (n = t.charAt(zt), zt++) : (n = u, 0 === Nt && Jt(H)), n !== u) for (; n !== u;) r.push(n), $.test(t.charAt(zt)) ? (n = t.charAt(zt), zt++) : (n = u, 0 === Nt && Jt(H));else r = u;
      return r !== u && (Rt = e, r = gt()), Nt--, (e = r) === u && (r = u, 0 === Nt && Jt(U)), e;
    }
    function re() {
      var e, r, n;
      return Nt++, e = zt, Zt(), (r = ce()) !== u ? (37 === t.charCodeAt(zt) ? (n = p, zt++) : (n = u, 0 === Nt && Jt(B)), n !== u ? (Rt = e, e = vt(r)) : (zt = e, e = u)) : (zt = e, e = u), Nt--, e === u && 0 === Nt && Jt(_), e;
    }
    function ne() {
      var e, r, n;
      return Nt++, e = zt, Zt(), (r = ce()) !== u ? (t.substr(zt, 2) === h ? (n = h, zt += 2) : (n = u, 0 === Nt && Jt(G)), n !== u ? (Rt = e, e = dt(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, Zt(), (r = ce()) !== u ? (t.substr(zt, 2) === m ? (n = m, zt += 2) : (n = u, 0 === Nt && Jt(J)), n !== u ? (Rt = e, e = yt(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, Zt(), (r = ce()) !== u ? (t.substr(zt, 2) === g ? (n = g, zt += 2) : (n = u, 0 === Nt && Jt(K)), n !== u ? (Rt = e, e = At(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, Zt(), (r = ce()) !== u ? (t.substr(zt, 2) === v ? (n = v, zt += 2) : (n = u, 0 === Nt && Jt(L)), n !== u ? (Rt = e, e = wt(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, Zt(), (r = ce()) !== u ? (t.substr(zt, 2) === d ? (n = d, zt += 2) : (n = u, 0 === Nt && Jt(Q)), n !== u ? (Rt = e, e = xt(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, Zt(), (r = ce()) !== u ? (t.substr(zt, 2) === y ? (n = y, zt += 2) : (n = u, 0 === Nt && Jt(V)), n !== u ? (Rt = e, e = bt(r)) : (zt = e, e = u)) : (zt = e, e = u)))))), Nt--, e === u && 0 === Nt && Jt(D), e;
    }
    function ue() {
      var e, r, n;
      return Nt++, e = zt, (r = ce()) !== u ? (t.substr(zt, 3) === A ? (n = A, zt += 3) : (n = u, 0 === Nt && Jt(X)), n !== u ? (Rt = e, e = Ct(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, (r = ce()) !== u ? (t.substr(zt, 3) === w ? (n = w, zt += 3) : (n = u, 0 === Nt && Jt(Y)), n !== u ? (Rt = e, e = Ft(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, (r = ce()) !== u ? (t.substr(zt, 4) === x ? (n = x, zt += 4) : (n = u, 0 === Nt && Jt(Z)), n !== u ? (Rt = e, e = Et(r)) : (zt = e, e = u)) : (zt = e, e = u), e === u && (e = zt, (r = ce()) !== u ? (t.substr(zt, 4) === b ? (n = b, zt += 4) : (n = u, 0 === Nt && Jt(tt)), n !== u ? (Rt = e, e = jt(r)) : (zt = e, e = u)) : (zt = e, e = u)))), Nt--, e === u && (r = u, 0 === Nt && Jt(W)), e;
    }
    function ae() {
      var t, e;
      return Nt++, t = zt, Zt(), (e = ce()) !== u ? (Rt = t, t = $t(e)) : (zt = t, t = u), Nt--, t === u && 0 === Nt && Jt(et), t;
    }
    function oe() {
      var e, r, n, a;
      if (Nt++, e = zt, 35 === t.charCodeAt(zt) ? (r = C, zt++) : (r = u, 0 === Nt && Jt(nt)), r !== u) {
        if (n = [], O.test(t.charAt(zt)) ? (a = t.charAt(zt), zt++) : (a = u, 0 === Nt && Jt(ut)), a !== u) for (; a !== u;) n.push(a), O.test(t.charAt(zt)) ? (a = t.charAt(zt), zt++) : (a = u, 0 === Nt && Jt(ut));else n = u;
        n !== u ? (Rt = e, e = Ot()) : (zt = e, e = u);
      } else zt = e, e = u;
      return e === u && (e = zt, (r = Wt()) !== u && (Rt = e, r = kt(r)), (e = r) === u && (e = zt, (r = ee()) !== u && (Rt = e, r = qt()), e = r)), Nt--, e === u && (r = u, 0 === Nt && Jt(rt)), e;
    }
    function ce() {
      var e, r, n, a, o, c, i;
      for (e = zt, k.test(t.charAt(zt)) ? (t.charAt(zt), zt++) : 0 === Nt && Jt(at), r = zt, n = [], q.test(t.charAt(zt)) ? (a = t.charAt(zt), zt++) : (a = u, 0 === Nt && Jt(ot)); a !== u;) n.push(a), q.test(t.charAt(zt)) ? (a = t.charAt(zt), zt++) : (a = u, 0 === Nt && Jt(ot));
      if (46 === t.charCodeAt(zt) ? (a = F, zt++) : (a = u, 0 === Nt && Jt(ct)), a !== u) {
        if (o = [], q.test(t.charAt(zt)) ? (c = t.charAt(zt), zt++) : (c = u, 0 === Nt && Jt(ot)), c !== u) for (; c !== u;) o.push(c), q.test(t.charAt(zt)) ? (c = t.charAt(zt), zt++) : (c = u, 0 === Nt && Jt(ot));else o = u;
        o !== u ? r = n = [n, a, o] : (zt = r, r = u);
      } else zt = r, r = u;
      if (r === u) if (r = [], q.test(t.charAt(zt)) ? (n = t.charAt(zt), zt++) : (n = u, 0 === Nt && Jt(ot)), n !== u) for (; n !== u;) r.push(n), q.test(t.charAt(zt)) ? (n = t.charAt(zt), zt++) : (n = u, 0 === Nt && Jt(ot));else r = u;
      if (r !== u) {
        if (n = zt, 101 === t.charCodeAt(zt) ? (a = E, zt++) : (a = u, 0 === Nt && Jt(it)), a !== u) {
          if (k.test(t.charAt(zt)) ? (o = t.charAt(zt), zt++) : (o = u, 0 === Nt && Jt(at)), o === u && (o = null), c = [], q.test(t.charAt(zt)) ? (i = t.charAt(zt), zt++) : (i = u, 0 === Nt && Jt(ot)), i !== u) for (; i !== u;) c.push(i), q.test(t.charAt(zt)) ? (i = t.charAt(zt), zt++) : (i = u, 0 === Nt && Jt(ot));else c = u;
          c !== u ? n = a = [a, o, c] : (zt = n, n = u);
        } else zt = n, n = u;
        n === u && (n = null), Rt = e, e = St();
      } else zt = e, e = u;
      return e;
    }
    function ie(t, e) {
      return t.map(function (t) {
        return t[e];
      });
    }
    function se(t, e, r) {
      return [t].concat(ie(e, r));
    }
    if ((n = c()) !== u && zt === t.length) return n;
    throw n !== u && zt < t.length && Jt(_t()), Kt(Pt, Mt < t.length ? t.charAt(Mt) : null, Mt < t.length ? Gt(Mt, Mt + 1) : Gt(Mt, Mt));
  }
  return t(e, Error), e.prototype.format = function (t) {
    var e = "Error: " + this.message;
    if (this.location) {
      var n,
        u = null;
      for (n = 0; n < t.length; n++) if (t[n].source === this.location.source) {
        u = t[n].text.split(/\r\n|\n|\r/g);
        break;
      }
      var a = this.location.start,
        o = this.location.source && "function" == typeof this.location.source.offset ? this.location.source.offset(a) : a,
        c = this.location.source + ":" + o.line + ":" + o.column;
      if (u) {
        var i = this.location.end,
          s = r("", o.line.toString().length, " "),
          l = u[a.line - 1],
          f = (a.line === i.line ? i.column : l.length + 1) - a.column || 1;
        e += "\n --\x3e " + c + "\n" + s + " |\n" + o.line + " | " + l + "\n" + s + " | " + r("", a.column - 1, " ") + r("", f, "^");
      } else e += "\n at " + c;
    }
    return e;
  }, e.buildMessage = function (t, e) {
    var r = {
      literal: function (t) {
        return '"' + u(t.text) + '"';
      },
      class: function (t) {
        var e = t.parts.map(function (t) {
          return Array.isArray(t) ? a(t[0]) + "-" + a(t[1]) : a(t);
        });
        return "[" + (t.inverted ? "^" : "") + e.join("") + "]";
      },
      any: function () {
        return "any character";
      },
      end: function () {
        return "end of input";
      },
      other: function (t) {
        return t.description;
      }
    };
    function n(t) {
      return t.charCodeAt(0).toString(16).toUpperCase();
    }
    function u(t) {
      return t.replace(/\\/g, "\\\\").replace(/"/g, '\\"').replace(/\0/g, "\\0").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/[\x00-\x0F]/g, function (t) {
        return "\\x0" + n(t);
      }).replace(/[\x10-\x1F\x7F-\x9F]/g, function (t) {
        return "\\x" + n(t);
      });
    }
    function a(t) {
      return t.replace(/\\/g, "\\\\").replace(/\]/g, "\\]").replace(/\^/g, "\\^").replace(/-/g, "\\-").replace(/\0/g, "\\0").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/[\x00-\x0F]/g, function (t) {
        return "\\x0" + n(t);
      }).replace(/[\x10-\x1F\x7F-\x9F]/g, function (t) {
        return "\\x" + n(t);
      });
    }
    function o(t) {
      return r[t.type](t);
    }
    function c(t) {
      var e,
        r,
        n = t.map(o);
      if (n.sort(), n.length > 0) {
        for (e = 1, r = 1; e < n.length; e++) n[e - 1] !== n[e] && (n[r] = n[e], r++);
        n.length = r;
      }
      switch (n.length) {
        case 1:
          return n[0];
        case 2:
          return n[0] + " or " + n[1];
        default:
          return n.slice(0, -1).join(", ") + ", or " + n[n.length - 1];
      }
    }
    function i(t) {
      return t ? '"' + u(t) + '"' : "end of input";
    }
    return "Expected " + c(t) + " but " + i(e) + " found.";
  }, {
    SyntaxError: e,
    parse: n
  };
}, (m = v).exports && (m.exports = g());
var d = v.exports;
function y(t) {
  if (!t || 0 === t.length) return null;
  if ("string" == typeof t) {
    const e = A(t);
    return e && 0 !== e.length ? e : null;
  }
  const e = t.map(t => {
    if (!Number.isFinite(t.scale) || t.scale <= 0) throw new u("effect:invalid-scale", "scale must be finite and greater than 0", {
      stop: t
    });
    return {
      scale: t.scale,
      effects: A(t.value)
    };
  });
  e.sort((t, e) => e.effects.length - t.effects.length);
  for (let r = 0; r < e.length - 1; r++) {
    if (!p(e[r].effects, e[r + 1].effects)) throw new u("effect:interpolation-impossible", "Cannot interpolate by scale between 2 lists of mixed effects", {
      a: e[r].effects,
      b: e[r + 1].effects
    });
    h(e[r].effects, e[r + 1].effects);
  }
  return e.sort((t, e) => e.scale - t.scale), e;
}
function A(t) {
  let e;
  if (!t) return [];
  try {
    e = d.parse(t);
  } catch (r) {
    throw new u("effect:invalid-syntax", "Invalid effect syntax", {
      value: t,
      error: r
    });
  }
  return e.map(t => w(t));
}
function w(t) {
  try {
    switch (t.name) {
      case "grayscale":
      case "sepia":
      case "saturate":
      case "invert":
      case "brightness":
      case "contrast":
        return x(t);
      case "opacity":
        return b(t);
      case "hue-rotate":
        return C(t);
      case "blur":
        return F(t);
      case "drop-shadow":
        return E(t);
      case "bloom":
        return j(t);
    }
  } catch (e) {
    throw e.details.filter = t, e;
  }
  throw new u("effect:unknown-effect", `Effect '${t.name}' is not supported`, {
    effect: t
  });
}
function x(t) {
  let e = 1;
  return $(t.parameters, 1), 1 === t.parameters.length && (e = P(t.parameters[0])), new a(t.name, e);
}
function b(t) {
  let e = 1;
  return $(t.parameters, 1), 1 === t.parameters.length && (e = P(t.parameters[0])), new o(e);
}
function C(t) {
  let e = 0;
  return $(t.parameters, 1), 1 === t.parameters.length && (e = T(t.parameters[0])), new c(e);
}
function F(t) {
  let e = 0;
  return $(t.parameters, 1), 1 === t.parameters.length && (e = U(t.parameters[0]), k(e, t.parameters[0])), new i(e);
}
function E(t) {
  const e = [];
  let r = null;
  for (const n of t.parameters) if ("color" === n.type) {
    if (e.length && Object.freeze(e), r) throw new u("effect:type-error", "Accepts only one color", {});
    r = H(n);
  } else {
    const t = U(n);
    if (Object.isFrozen(e)) throw new u("effect:type-error", "<length> parameters not consecutive", {
      lengths: e
    });
    e.push(t), 3 === e.length && k(t, n);
  }
  if (e.length < 2 || e.length > 3) throw new u("effect:type-error", `Expected <length>{2,3}, Actual: <length>{${e.length}}`, {
    lengths: e
  });
  return new s(e[0], e[1], e[2] || 0, r || _("black"));
}
function j(t) {
  let e = 1,
    r = 0,
    n = 0;
  return $(t.parameters, 3), t.parameters[0] && (e = P(t.parameters[0])), t.parameters[1] && (r = U(t.parameters[1]), k(r, t.parameters[1])), t.parameters[2] && (n = P(t.parameters[2])), new l(e, r, n);
}
function $(t, e) {
  if (t.length > e) throw new u("effect:type-error", `Function supports up to ${e} parameters, Actual: ${t.length}`, {
    parameters: t
  });
}
function O(t) {
  if ("color" === t.type) return "<color>";
  if (t.unit) {
    if (t.unit in I) return "<length>";
    if (t.unit in z) return "<angle>";
    if ("%" === t.unit) return "<percentage>";
  }
  return "<double>";
}
function k(t, e) {
  if (t < 0) throw new u("effect:type-error", `Negative values are not allowed, Actual: ${t}`, {
    term: e
  });
}
function q(t) {
  if ("quantity" !== t.type || null !== t.unit) throw new u("effect:type-error", `Expected <double>, Actual: ${O(t)}`, {
    term: t
  });
}
function S(t) {
  if ("quantity" !== t.type || null !== t.unit && "%" !== t.unit) throw new u("effect:type-error", `Expected <double> or <percentage>, Actual: ${O(t)}`, {
    term: t
  });
}
const z = {
  deg: 1,
  grad: .9,
  rad: 180 / Math.PI,
  turn: 360
};
function R(t) {
  if ("quantity" !== t.type || !(0 === t.value && null === t.unit || t.unit && null != z[t.unit])) throw new u("effect:type-error", `Expected <angle>, Actual: ${O(t)}`, {
    term: t
  });
}
const I = {
  px: 1,
  cm: 96 / 2.54,
  mm: 96 / 2.54 / 10,
  in: 96,
  pc: 16,
  pt: 96 / 72
};
function M(t) {
  if ("quantity" !== t.type || !(0 === t.value && null === t.unit || t.unit && null != I[t.unit])) throw new u("effect:type-error", `Expected <length>, Actual: ${O(t)}`, {
    term: t
  });
}
function P(t) {
  S(t);
  const e = t.value;
  return k(e, t), "%" === t.unit ? .01 * e : e;
}
function N(t) {
  return q(t), k(t.value, t), t.value;
}
function T(t) {
  return R(t), t.value * z[t.unit] || 0;
}
function U(t) {
  return M(t), t.value * I[t.unit] || 0;
}
function H(e) {
  switch (e.colorType) {
    case "hex":
      return t(e.value);
    case "named":
      return _(e.value);
    case "function":
      return G(e.value);
  }
}
function _(t) {
  if (!e(t)) throw new u("effect:unknown-color", `color '${t}' isn't valid`, {
    namedColor: t
  });
  return r(t);
}
const B = /^rgba?/i,
  D = /^hsla?/i;
function G(t) {
  if ($(t.parameters, 4), B.test(t.name)) return [P(t.parameters[0]), P(t.parameters[1]), P(t.parameters[2]), t.parameters[3] ? P(t.parameters[3]) : 1];
  if (D.test(t.name)) return n(N(t.parameters[0]), P(t.parameters[1]), P(t.parameters[2]), t.parameters[3] ? P(t.parameters[3]) : 1);
  throw new u("effect:syntax-error", `Invalid color function '${t.name}'`, {
    colorFunction: t
  });
}
export { y as parse };